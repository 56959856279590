<template>
    <v-card class="witness-list-form">
        <v-toolbar flat outlined>
            <v-icon @click="close()">mdi-close</v-icon>

            <v-spacer />

            <v-toolbar-title>Edit witness list</v-toolbar-title>

            <v-spacer />
            
            <v-btn @click="save()" small text>Save</v-btn>
        </v-toolbar>

        <v-card-text>
            <v-container>
                <v-form
                    ref="form"
                >
                    <v-row>
                        <RichTextEditor
                            label="Witness list"
                            v-model="witnessListLocal"
                            :required="true"
                            :rules="rules.required"
                            hint="Can you list the names of anyone that may be beneficial as a witness to Virgin?"
                        />
                    </v-row>

                    <v-row class="mt-5">
                        <v-alert type="info" dense>
                            These statements may be used in court at a later date if necessary.
                        </v-alert>
                    </v-row>
                </v-form>
            </v-container>
        </v-card-text>
    </v-card>
</template>

<script>
import RichTextEditor from "../../../components/RichTextEditor.vue";
import tasksAgent from "../../Tasks/tasksAgent";

export default {
    components: {
        RichTextEditor,
    },

    props: {
        id: String,
        witnessList: String,
    },
    
    data () {
        return {
            witnessListLocal: this.witnessList,
            
            rules: {
                required: [v => !!v || "Required"],
            },
        };
    },

    methods: {
        save () {
            var l = this.$coreUi.loading();
            
            // Load workItem
            tasksAgent.getTask(this.id).then(result => {

                // Set fields and save
                result.aspectData.formBuilder.formData["memoWitnessList"] = this.witnessListLocal || "";

                // Don't invoke these aspect savers (they could fail)
                delete result.aspectData.participantPicker;
                delete result.aspectData.taskAssignedTo;
                delete result.aspectData.taskDetails;
                delete result.aspectData.taskDueDate;
                
                tasksAgent.saveTask(this.id, result).then(() => {

                    l.dismiss();
                    this.close(true);

                }).catch(console.error);

            }).catch(console.error);
        },

        close (result) {
            this.$emit("close", result);
        }
    },
};
</script>

<style>
.witness-list-form.theme--light.v-sheet .tiptap-vuetify-editor > .v-card {
    margin: 0;
}

/* Avoid toolbar wrapping */
.witness-list-form.theme--light.v-sheet .tiptap-vuetify-editor__toolbar > .v-toolbar {
    padding: 5px 1px;
}
</style>